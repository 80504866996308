<template>
  <div class="video-alarm-list">
    <van-nav-bar left-arrow title="视频报警" @click-left="$router.back()" />
    <div class="search-query">
      <van-search
        v-model="queryInfo.videoName"
        class="search-bar"
        placeholder="请输入报警点位名称关键字"
        @clear="onRefresh"
        @search="onRefresh"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="datetime"
        format="{y}-{m}-{d} {h}:{i}"
      />
      <video-alarm-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="trouble-total">共{{ total }}条视频报警记录</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="video-alarm-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="[
            'video-alarm-list__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          :border="false"
          @click="gotoDetails(item)"
        >
          <div>
            <img
              class="alarm-image"
              :src="getFileUrl(item.signImg)"
              alt="报警图片"
              @click.stop="onShowImage(item.signImg)"
            />
            <div class="content">
              <h3 class="title">
                {{ item.videoName }}
              </h3>
              <div
                class="status-icon"
                :style="{ color: iconConf[item.checkStatus].color }"
              >
                <van-icon
                  :name="iconConf[item.checkStatus].name"
                  class-prefix="iconfont"
                />
              </div>
              <div class="type-text">
                <span>{{ item.alarmType }}</span>
                <span
                  :class="item.alarmStatus === '报警结束' ? 'end' : 'warning'"
                  >{{ item.alarmStatus }}</span
                >
              </div>
              <p class="time-text">{{ item.alarmStartTime }}</p>
            </div>
          </div>
          <div class="line-block" />
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import VideoAlarmQuery from "./components/VideoAlarmQuery";
import dayjs from "dayjs";
import { getPageVideoAlarm } from "@/api/warning";
import { getFileUrlForFileSystem } from "@/utils/file";
import { iconConf } from "./utils/constant";
import { mapState } from "vuex";
import { ImagePreview } from "vant";
export default {
  name: "VideoAlarmList",
  components: { VideoAlarmQuery },
  data() {
    return {
      iconConf,
      queryInfo: {
        videoName: "",
        orgCode: "",
        queryStartTime: "",
        queryEndTime: "",
        page: 1,
        size: 10
      },
      dateTime: { start: "", end: "" },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      urls: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.queryInfo.queryStartTime = this.formatDate(val.start);
          this.queryInfo.queryEndTime = this.formatDate(val.end);
          this.onRefresh();
        }
        if (!val.start && !val.end) {
          this.queryInfo.startTime = "";
          this.queryInfo.endTime = "";
          this.onRefresh();
        }
      },
      deep: true
    }
  },
  created() {
    this.queryInfo.orgCode = this.userInfo.orgCode;
  },
  activated() {
    this.onRefresh();
  },
  methods: {
    onShowImage(url) {
      url = this.getFileUrl(url);
      this.urls.push(url);
      ImagePreview(this.urls);
    },
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getFileUrl(fileId) {
      return getFileUrlForFileSystem(fileId, false);
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const data = await getPageVideoAlarm(this.queryInfo);
        const { list, total } = data || { list: [], total: 0 };
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails({ alarmId: id }) {
      this.$router.push({ name: "VideoAlarmDetail", params: { id } });
    }
  }
};
</script>

<style lang="scss">
.video-alarm-list {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .search-bar {
    padding-bottom: 0;
  }
  .trouble-total {
    text-align: center;
    color: #8c8f97;
    font-size: 14px;
    padding: 14px 0;
  }
  &__wrapper {
    overflow-y: auto;
  }
  &__rows {
    padding-left: 0;
    padding-right: 0;
    .van-cell__value > div {
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .alarm-image {
        width: 104px;
        height: 80px;
        border-radius: 6px;
        display: block;
        font-size: 0;
        margin-right: 12px;
      }

      .content {
        position: relative;
        flex: 1;
        color: #3b4664;
        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 20px;
          font-size: 14px;
        }
        .type-text {
          font-size: 12px;
          /*line-height: 1;*/
          span:last-child {
            padding: 2px 4px;
            border-radius: 2px;
            margin-left: 4px;
          }
          .warning {
            color: #fff;
            background-color: #fb7171;
          }
          .end {
            color: #8c8f97;
            background-color: #eceef2;
          }
        }
        .time-text {
          position: absolute;
          bottom: 0;
          width: 180px;
          font-size: 12px;
          line-height: 22px;
          color: #aeb3c0;
        }
        .status-icon {
          float: right;
          i {
            display: block;
            line-height: 40px;
            font-size: 40px;
          }
        }
      }
    }
    .line-block {
      margin: 0 -16px;
    }
  }
}
</style>
