import { deepFreeze } from "@/utils";
// 报警处置状态
export const alarmCheckStatus = deepFreeze([
  {
    label: "待确认",
    value: "1"
  },
  {
    label: "待反馈",
    value: "2"
  },
  {
    label: "已完成",
    value: "3"
  }
]);

export const iconConf = deepFreeze({
  1: {
    name: "daiqueren1",
    color: "#3683F4"
  },
  2: {
    name: "daifankui",
    color: "#F68446"
  },
  3: {
    name: "yiwancheng",
    color: "#8EB433"
  }
});
