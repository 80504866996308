import axios from "@/utils/axios";

import apiPath from "../utils/apiPath";
const WARNING_API = apiPath.warning;
const CAMERA_API = apiPath.camera;

/**
 * 获取所有企业字典
 * @param {*} params 不需要参数
 */
export const getIndicatorIdsSelect = params =>
  axios.get(WARNING_API + "/select/alarm/group/indicator-ids", { params });

/**
 * 环保指标下拉
 * @returns {*}
 */
export const getEnvGroupIndicatorIdsSelect = params => {
  return axios.get(WARNING_API + `/select/environment-group/indicator-ids`, {
    params
  });
};

/**
 * 核验报警
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function verificationAlarmById(id, data) {
  return axios.post(WARNING_API + `/alarm/approve/${id}`, data);
}

/**
 * 视频报警 - 报警类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getListVideoAlarmType() {
  return axios.get(CAMERA_API + `/list/video/alarm/type`);
}

/**
 * 视频报警 - 报警状态
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSelectVideoAlarmStatus() {
  return axios.get(CAMERA_API + `/select/video/alarm-status`);
}

/**
 * 分页查询视频报警列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageVideoAlarm(params) {
  return axios.get(CAMERA_API + `/page/video/alarm`, { params });
}

/**
 * 查看视频报警详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfoVideoAlarmById(id) {
  return axios.get(CAMERA_API + `/info/video/alarm/${id}`);
}

/**
 * 查询反馈列表
 * @param alarmId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getListVideoAlarmFeebackByAlarmId(alarmId) {
  return axios.get(CAMERA_API + `/list/video/alarm/feedback`, {
    params: { alarmId }
  });
}

/**
 * 确认报警
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function confirmVideoAlarm(data) {
  return axios.post(CAMERA_API + `/alarm/check-status`, data);
}

/**
 * 视频报警反馈
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function feedbackVideoAlarm(data) {
  return axios.post(CAMERA_API + `/add/video/alarm/feedback`, data);
}

export function getPageTwoLine(params) {
  return axios.get(WARNING_API + `/page/on/off`, { params });
}

export function getIndicatorsTipAll(params) {
  return axios.get(WARNING_API + `/list/indicators/tip-all`, { params });
}

export function getTwoLinePerson(params) {
  return axios.get(WARNING_API + `/on/off/users`, { params });
}

export function getListTwoLineBitNum(params) {
  return axios.get(WARNING_API + `/list/on/off/indicator`, { params });
}

export function getPageTwoLineLog(id, params) {
  return axios.get(WARNING_API + `/page/on/off/log/${id}`, { params });
}

export function approveTwoLine(data) {
  return axios.post(WARNING_API + `/approval/on/off`, data);
}

export function getInfoTwoLineById(id) {
  return axios.get(WARNING_API + `/info/on/off/${id}`);
}

export function getAuthTwoApproveById(id, userId) {
  return axios.get(
    WARNING_API + `/permission/on-off?id=${id}&userId=${userId}`
  );
}
